.page-layout {
	.top-bg {
		height: 16rem;
		max-height: 16rem;
		z-index: -1;
	}
}

#calendar .page-header:before {
	display: none;
}

.fc-day-grid-event > .fc-content {
    white-space: normal !important;
}