.dashboard {
	&-card, .doc &-card {
		height: auto;
		min-height: 0;
	}
	&__side-widget, .doc &__side-widget {
		margin-bottom: 3.2rem;
	}
}
#investment-overview-chart {
	height: 37rem;
}